<template>
  <div class="paymentBooking__cost-list">
    <div class="paymentBooking__cost-list-item">
      <div class="paymentBooking__cost-list-title">Flight:<span class="paymentBooking__cost-list-value">$ {{exclusiveTotalPrice}}</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ {{exclusiveTotalPrice}}</div> -->
    </div>
    <div class="paymentBooking__cost-list-item">
      <div class="paymentBooking__cost-list-title">Fee:<span class="paymentBooking__cost-list-value">$ {{totalFees}}</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ {{totalFees}}</div> -->
    </div>
    <div class="paymentBooking__cost-list-item">
      <div class="paymentBooking__cost-list-title"><span>FLY<b>Rewards</b>:</span><span class="paymentBooking__cost-list-value">$ N/A</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ N/A</div> -->
    </div>
    <div class="paymentBooking__cost-list-item">
      <div class="paymentBooking__cost-list-title">Tax:<span class="paymentBooking__cost-list-value">$ {{totalTaxes}}</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ {{totalTaxes}}</div> -->
    </div>
    <div class="paymentBooking__cost-list-item paymentBooking__cost-list-item--flyGreen">
      <div class="paymentBooking__cost-list-title">FLYGREEN:<span class="paymentBooking__cost-list-value">$ {{flyGreenPurchaseValue}}</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ {{flyGreenPurchaseValue}}</div> -->
      <!-- <span
        class="paymentBooking__cost-list-link js-popup"
        @click="$modal.show('FlyGreenPopup')"
        data-mod="flyGreen-popup"
      >
          <SvgIcon name="info"/>
        </span> -->
    </div>
    <div class="paymentBooking__cost-list-item paymentBooking__cost-list-item--total">
      <div class="paymentBooking__cost-list-title">Total:<span class="paymentBooking__cost-list-value">$ {{total}}</span></div>
      <!-- <div class="paymentBooking__cost-list-value">$ {{total}}</div> -->
    </div>
    <FlyGreenPopup/>
  </div>
</template>

<script>
  // import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import FlyGreenPopup from '@/components/common/modals/FlyGreenPopup'

  export default {
    name: 'CostBlock',
    data: () => ({
      exclusiveTotalPrice: 0,
      totalFees: 0,
      totalTaxes: 0,
      flyGreenPurchaseValue: 0,
      total: 0,
    }),
    created () {
      // console.log(this.$store.state.bookingFromSearch.fullBookingItem)
      const { exclusiveTotalPrice, totalFees, totalTaxes } = this.$store.state.bookingFromSearch.fullBookingItem

      this.exclusiveTotalPrice = this.formatDaCheddah(exclusiveTotalPrice)
      this.totalFees = this.formatDaCheddah(totalFees)
      this.totalTaxes = this.formatDaCheddah(totalTaxes)

      this.flyGreenPurchaseValue = this.$store.state.flygreen.offsetPurchasePayload.offsetPrice

      this.total = this.formatDaCheddah(
        parseFloat(exclusiveTotalPrice) +
        parseFloat(totalFees) +
        parseFloat(totalTaxes) +
        parseFloat(this.flyGreenPurchaseValue),
      )
    },
    methods: {
      formatDaCheddah (amount) {
        return amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    },
    components: {
      // SvgIcon,
      FlyGreenPopup,
    },
  }
</script>
